import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

import { postData, getData, putData } from '../services/api';
import { useSession } from '../assets/auth/SessionContext';

import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";
import { InputNumber } from "primereact/inputnumber";

export default function DeviceUserModal(modalData: any) {

    const [formData, setFormData] = useState(modalData.selectedDataItem);
    const [usernameEditable, setUsernameEditable] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [canScan, setCanScan] = useState(false);
    const [commission, setCommission] = useState(0);
    const [modalHeader, setModalHeader] = useState("");
    const [selectedPaymentTypes, setSelectedPaymentTypes] = useState([]);
    const [portalCodeReadOnly, setPortalCodeReadOnly] = useState(!modalData.selectedDataItem?.usernameEditable && modalData.selectedDataItem?.portalCode
    );

    const [saveDisabled, setSaveDisabled] = useState(false);

    const [roles, setRoles] = useState([]);
    const [teams, setTeams] = useState([]);

    const toast = useRef<Toast>(null);

    const { session } = useSession();

    const paymentTypeOptions = [
        {
            value: "cash",
            name: "Cash"
        },
        {
            value: "card",
            name: "Card"
        },
        {
            value: "paymentLink",
            name: "Payment Link"
        }
    ]

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handlePaymentTypeChange = (e: any) => {
        setSelectedPaymentTypes(e.value);
    };


    useEffect(() => {
        if (modalData.mode === "create") {
            setUsernameEditable(true)
            setModalHeader("Creating seller")
        } else {
            setUsernameEditable(false)
            setModalHeader("Updating seller")
        }

        setCanScan(formData.canScan);
        setCommission(formData.commission);
        setSelectedTeams(formData.teams || []);
        setSelectedPaymentTypes(formData.acceptedPayments || []);
        setSelectedRole(formData.role || []);

        getData(session, `teams`, null).then(teams => {

            const teamsForMultiSelect = teams.map((x: { name: any; ref: any }) => ({
                name: x.name,
                value: x.ref
            }));

            setTeams(teamsForMultiSelect);

            getData(session, `roles`, "isDevice=true").then(roles => {

                const rolesForDropdown = roles.map((x: { name: any; ref: any; }) => ({
                    name: x.name,
                    value: x.ref
                }));

                setRoles(rolesForDropdown);
            });
        });
    }, []);

    // useEffect(() => {
    //     if (roles && roles.length > 0) {
    //         setSelectedRole(formData.role);
    //     }
    // }, [formData.role, roles]);

    // useEffect(() => {
    //     if (teams && teams.length > 0) {
    //         setSelectedTeams(formData.teams);
    //     }
    // }, [formData.teams, setSelectedTeams, teams]);

    function save(data: any) {

        try {

            if (!data.username) {
                throw new Error("Username is required")
            }

            if (!data.firstName || !data.lastName) {
                throw new Error("Both first and last name are required")
            }

            if (!data.email) {
                throw new Error("E-Mail is required")
            } else {
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if (!regex.test(data.email)) {
                    throw new Error("E-Mail address not valid")
                }
            }

            if (data.portalCode && (data.portalCode?.toLowerCase() === data.username.toLowerCase() || (data.username.length > 3 && data?.portalCode?.length > 3 && data.username.toLowerCase().includes(data?.portalCode?.toLowerCase())))) {
                throw new Error("Username and portal code cannot be the same or contain the username")
            }

            if (modalData.mode === "create") {
                if (!data.password) {
                    throw new Error("Password is required")
                }
            }

            if (data.password) {
                if (data.password !== data.confirmPassword) {
                    throw new Error("Passwords do not match")
                }
            }

            if (!selectedRole) {
                throw new Error("Role is required")
            }

            if (!selectedTeams || (selectedTeams && selectedTeams.length <= 0)) {
                throw new Error("At least 1 team must be selected")
            }

            if (!selectedPaymentTypes || (selectedPaymentTypes && selectedPaymentTypes.length <= 0)) {
                throw new Error("At least 1 payment type must be selected")
            }

            let user = {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                username: data.username,
                ref: data.username,
                teams: selectedTeams,
                role: selectedRole,
                canScan: canScan,
                commission: commission,
                acceptedPayments: selectedPaymentTypes,
                portalCode: data.portalCode
            } as any;

            if (data.password) {
                user = { ...user, password: data.password };
            }

            if (modalData.mode === "update") {
                putData(session, `device-users/${user.username}`, user, null).then(updateUserData => {

                    user.displayName = `${user.firstName} ${user.lastName}`;

                    modalData.closeModal({ toast: true, message: `${user.displayName} updated`, updateRecord: user });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'User created', detail: "" });
                    }

                    setSaveDisabled(false);
                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }
                });
            } else if (modalData.mode === "create") {

                postData(session, `device-users`, user, null).then(newUserData => {

                    user.displayName = `${user.firstName} ${user.lastName}`;

                    modalData.closeModal({ toast: true, message: `${user.displayName} created`, newRecord: newUserData });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'User created', detail: "" });
                    }

                    setSaveDisabled(false);
                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }

                    setSaveDisabled(false);
                });
            }
        } catch (error: any) {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: error.message });
            }

            setSaveDisabled(false);
        }
    }

    return (

        <div className="modal-container">

            <Toast ref={toast} />
            <div className="modal ">
                <div className="modal-header">
                    <span className="modal-title">{modalHeader}</span>
                    <span className="modal-close" onClick={modalData.closeModal}>&times;</span>
                </div>
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="username">Username</label>
                        <input
                            className="text-input"
                            readOnly={!usernameEditable}
                            type="text"
                            id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="firstName">First Name</label>
                        <input
                            className="text-input"
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="lastName">Last Name</label>
                        <input
                            className="text-input"
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="email">Email</label>
                        <input
                            className="text-input"
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="password">Password</label>
                        <input
                            className="text-input"
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="confirmPassword">Confirm Password</label>
                        <input
                            className="text-input"
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                        />
                    </div>
                </div>


                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="role">Role</label>
                        <Dropdown
                            onChange={(e) => { setSelectedRole(e.value); }}
                            options={roles}
                            optionLabel="name"
                            placeholder="Select role"
                            className="dropdown"
                            value={selectedRole} />

                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="teams">Teams</label>
                        <MultiSelect value={selectedTeams} onChange={(e) => setSelectedTeams(e.value)} options={teams} optionLabel="name"
                            filter placeholder="Select Teams" className="dropdown" />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="name">Additional Commission - €</label>
                        <InputNumber className="text-input-number" min={0} value={commission} onValueChange={(e) => setCommission(e.value || 0)} />
                    </div>
                </div>

                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="role">Can Scan?</label>
                        <div className="input-switch-min-width">
                            <InputSwitch
                                checked={canScan}
                                onChange={(e) => setCanScan(e.value)}
                            />
                        </div>
                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="text-label">Payment Types</label>
                        <MultiSelect value={selectedPaymentTypes} onChange={handlePaymentTypeChange} options={paymentTypeOptions} optionLabel="name"
                            filter placeholder="Select Payment Types" className="dropdown" />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="username">Portal Code</label>
                        <input
                            className="text-input"
                            readOnly={portalCodeReadOnly}
                            type="text"
                            id="portalCode"
                            name="portalCode"
                            value={formData.portalCode}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="footer">
                    <button disabled={saveDisabled} className="save-button" type="button" onClick={() => {
                        setSaveDisabled(true);
                        save(formData);
                    }
                    }>Save</button>
                    <button type="button" className="cancel-button" onClick={modalData.closeModal}>Cancel</button>
                </div>
            </div>
        </div >
    )
}