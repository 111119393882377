import { Toast } from "primereact/toast";
import { useSession } from "../assets/auth/SessionContext";
import { useEffect, useRef, useState } from "react";
import { getData, postData } from "../services/api";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import Table from "./../components/Table";
import { Button } from "primereact/button";
import { Eraser, PlusCircle } from "lucide-react";

interface TicketType {
    name: string | undefined;
    ref: string;
}

export default function CreateExternalTicketModal(modalData: any) {

    const [formData, setFormData] = useState(modalData.event);
    const [data, setData] = useState<any>([]);
    const [modalHeader, setModalHeader] = useState("");
    const [ticketTypes, setTicketTypes] = useState<TicketType[]>([]);
    const [selectedTicketType, setSelectedTicketType] = useState(null);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [displayName, setDisplayName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [price, setPrice] = useState(0);
    const [email, setEmail] = useState("");
    const [ref, setRef] = useState("");
    const [source, setSource] = useState("");


    const toast = useRef<Toast>(null);

    const { session } = useSession();

    const validateEmail = (email: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    function removeTicketItem(itemId: string) {
        try {
            const index = data.findIndex((x: { ref: string; }) => x.ref === itemId);
            const newDataItems = JSON.parse(JSON.stringify(data));
            newDataItems.splice(index, 1);
            setData([...newDataItems])

            return { success: true }
        } catch (err) {

            return { success: false, message: "Something went wrong" }
        }
    }

    function addItem() {
        try {

            if (!ref) {
                throw new Error("QR code is required")
            }

            if (!selectedTicketType) {
                throw new Error("Ticket type is required")
            }

            if (!displayName) {
                throw new Error("Display name is required")
            }

            if (!email) {
                throw new Error("E-Mail is required")
            }

            if (!validateEmail(email)) {
                throw new Error("E-Mail is not valid")
            }

            if (!phoneNumber) {
                throw new Error("Phone number is required")
            }

            if (!price) {
                throw new Error("Price is required")
            }

            if (!source) {
                throw new Error("Source is required")
            }

            let newTicket = {
                ref: ref,
                displayName: displayName,
                email: email,
                phoneNumber: phoneNumber,
                price: price,
                source: source,
                ticketTypeRef: selectedTicketType,
                eventRef: formData.ref,
                eventName: formData.name
            } as any;

            const ticketTypeName = ticketTypes.find((x: any) => x.value === selectedTicketType)?.name || "";

            newTicket = { ...newTicket, isScanned: false, ticketTypeName: ticketTypeName };

            if (data.find((x: { ref: string; }) => x.ref === ref)) {
                throw new Error("QR code already entered")
            }

            setData([...data, newTicket])
            setRef("");

            setSaveDisabled(false);

            return;



        } catch (error: any) {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: error.message });
            }

            setSaveDisabled(false);
        }
    }

    function save() {
        try {

            postData(session, `tickets/external/create`, data, null).then(createTicket => {

                modalData.closeModal({ toast: true, message: `Tickets created`, newRecords: data, success: true });
                setSaveDisabled(false);
            }).catch(err => {
                if (toast.current) {
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                }

                setSaveDisabled(false);
            });
        } catch (error: any) {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: error.message });
            }

            setSaveDisabled(false);
        };
    }

    function clear() {
        setRef("");
        setDisplayName("");
        setEmail("");
        setPhoneNumber("");
        setSource("");
        setPrice(0);
        setSelectedTicketType(null);
    }

    useEffect(() => {

        setModalHeader("Creating External Tickets")

        getData(session, `events/${formData.ref}/ticket-types`, "isDropdown=true").then(ticketTypesResult => {

            if (ticketTypesResult.message) {
                const message = 'No ticket types found for this event';

                if (toast.current) {
                    toast.current.show({ severity: 'error', summary: message, detail: "" });
                }

                modalData.closeModal({ message, success: false });
            } else {
                const newTicketTypes = ticketTypesResult.ticketTypes.map((x: { name: any; ref: any }) => ({
                    name: x.name,
                    value: x.ref
                }));

                setTicketTypes(newTicketTypes);
            }
        });
    }, []);

    return (
        <div className="flex">
            <div className="modal-container">

                <Toast ref={toast} />
                <div className="modal ">

                    <div className="modal-header">
                        <span className="modal-title">{modalHeader}</span>
                        <span className="modal-close" onClick={modalData.closeModal}>&times;</span>
                    </div>

                    <div className="flex">
                        <div className="flex-1 flex m-2 px-5 py-3 ">
                            <label className="text-label" htmlFor="ref">Event Name</label>
                            <input
                                className="text-input"
                                readOnly={true}
                                type="text"
                                id="eventName"
                                name="eventName"
                                value={formData.name}
                            />
                        </div>
                        <div className="flex-1 flex m-2 px-5 py-3">
                            <label className="text-label" htmlFor="ticketType">Select Ticket Type</label>
                            <Dropdown
                                onChange={(e) => { setSelectedTicketType(e.value); }}
                                options={ticketTypes}
                                optionLabel="name"
                                placeholder="Select ticket type"
                                className="dropdown"
                                value={selectedTicketType}
                            />

                        </div>
                        <div className="flex-1 flex m-2 px-5 py-3">

                            <label className="text-label" htmlFor="ref">QR code</label>
                            <input
                                className="text-input"
                                type="text"
                                id="ref"
                                name="ref"
                                value={ref}
                                onChange={(e) => setRef(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex-1 flex m-2 px-5 py-3">

                            <label className="text-label" htmlFor="name">Display Name</label>
                            <input
                                className="text-input"
                                type="text"
                                id="name"
                                name="name"
                                value={displayName}
                                onChange={(e) => setDisplayName(e.target.value)}
                            />
                        </div>
                        <div className="flex-1 flex m-2 px-5 py-3">
                            <label className="text-label" htmlFor="ref">E-Mail</label>
                            <input
                                className="text-input"
                                type="text"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="flex-1 flex m-2 px-5 py-3">
                            <label className="text-label" htmlFor="ref">Phone Number</label>
                            <input
                                className="text-input"
                                type="text"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex-1 flex m-2 px-5 py-3 ">
                            <label className="text-label" htmlFor="ref">Source</label>
                            <input
                                className="text-input"
                                type="text"
                                id="source"
                                name="source"
                                value={source}
                                onChange={(e) => setSource(e.target.value)}
                            />

                        </div>
                        <div className="flex-1 flex m-2 px-5 py-3">
                            <label className="text-label" htmlFor="price">Price</label>
                            <InputNumber inputId="currency-germany" className="text-input-number" value={price} onValueChange={(e) => setPrice(e.value || 0)} mode="currency" currency="EUR" />
                        </div>
                        <div className="flex-1 flex m-2 px-5 py-3">

                        </div>
                    </div>

                    <div className="flex">
                        <div className='add-container-create-external-ticket'>
                            <Button className="eraser-button" type="button"
                                onClick={() => {
                                    clear();
                                }}
                                icon={<Eraser size={30} />}>
                            </Button>

                            <Button className="add-button" type="button"
                                onClick={() => {
                                    setSaveDisabled(true);
                                    addItem();
                                }}
                                icon={<PlusCircle size={80} />}>
                            </Button>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="table-container">
                            <Table
                                addButtonVisible={false}
                                data={data}
                                type="createExternalTickets"
                                remove={removeTicketItem}
                                editButtonVisible={false}
                            />
                        </div>
                    </div>

                    <div className="footer">
                        <button disabled={saveDisabled} className="save-button" type="button" onClick={() => {
                            setSaveDisabled(true);
                            save();
                        }
                        }>Save</button>
                        <button type="button" className="cancel-button" onClick={modalData.closeModal}>Cancel</button>
                    </div>
                </div>
            </div >
        </div>
    );
}