import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { postData } from "../services/api";
import { InputSwitch } from "primereact/inputswitch";

import { useSession } from '../assets/auth/SessionContext';
import CustomDateTimePicker from "../services/CustomDateTimePicker";

export default function CloneEventModal(modalData: any) {

    const defaultDate = dayjs();
    defaultDate.hour(0);
    defaultDate.minute(0);

    const { session } = useSession();

    const [formData, setFormData] = useState(modalData.selectedDataItem);
    const [modalHeader, setModalHeader] = useState("");

    const [dateFrom, setDateFrom] = useState<Dayjs | null>(defaultDate);
    const [dateTo, setDateTo] = useState<Dayjs | null>(defaultDate);
    const [active, setActive] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false);

    const toast = useRef<Toast>(null);

    useEffect(() => {
        setModalHeader("Clone Event");
    }, []);

    const handleDateFromChange = (id: string, value: Dayjs | null) => {
        setDateFrom(dayjs(value));
    };

    const handleDateToChange = (id: string, value: Dayjs | null) => {
        setDateTo(dayjs(value));
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    function save(data: any) {

        try {

            if (!data.name) {
                setSaveDisabled(false);
                throw new Error("Name is required")
            }

            if (data.eventCode && data.eventCode.length !== 0 && data.eventCode.length < 6) {
                setSaveDisabled(false);
                throw new Error("Event Code must be 6 characters long");
            }

            const clonedEvent = {
                name: data.name,
                dateFrom: dayjs(dateFrom),
                dateTo: dayjs(dateTo),
                eventCode: data.eventCode,
                active: active
            } as any;

            postData(session, `events/clone/${data.ref}`, clonedEvent, null).then(event => {
                event.data.formatDate = dayjs(clonedEvent.date).format("DD-MM-YYYY");

                modalData.closeModal({ toast: true, message: "Event Clone", newRecord: event });

                setSaveDisabled(false);

            }).catch(err => {
                if (toast.current) {
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                }

                setSaveDisabled(false);
            });
        } catch (error: any) {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: error.message });
            }

            setSaveDisabled(false);
        }
    }

    return (
        <div className="modal-container">
            <Toast ref={toast} />
            <div className="modal">

                <div className="modal-header">
                    <span className="modal-title">{modalHeader}</span>
                    <span className="modal-close" onClick={modalData.closeModal}>&times;</span>
                </div>
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">From</label>
                        <CustomDateTimePicker id="from" value={dateFrom} onChange={handleDateFromChange} />
                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">To</label>
                        <CustomDateTimePicker id="to" value={dateTo} onChange={handleDateToChange} />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 ">

                    </div>
                </div>
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Name</label>
                        <input
                            className="text-input"
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="eventCode">Scanning Code</label>
                        <input
                            className="text-input"
                            type="text"
                            id="eventCode"
                            name="eventCode"
                            value={formData.eventCode}
                            onChange={handleChange}
                            maxLength={6}
                        />
                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="name">Active</label>
                        <div className="input-switch-min-width">
                            <InputSwitch
                                checked={active}
                                onChange={(e) => setActive(e.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="footer">
                    <button disabled={saveDisabled} className="save-button" type="button" onClick={() => {
                        setSaveDisabled(true);
                        save(formData);
                    }}>Clone</button>
                    <button type="button" className="cancel-button" onClick={modalData.closeModal}>Cancel</button>
                </div>
            </div>
        </div >
    );
}
