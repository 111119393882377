import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import Table from "../components/Table";
import { InputNumber } from "primereact/inputnumber";
import { postData } from "../services/api";
import { useSession } from "../assets/auth/SessionContext";

export default function TicketRefundModal(modalData: any) {
    const [formData] = useState(modalData.selectedDataItem);
    const [type] = useState("refund");
    const [modalHeader, setModalHeader] = useState("");
    const [amount, setAmount] = useState(0);
    const [selectedRefundItem, setSelectedRefundItem] = useState<{ ref: String, amount: number } | null>(null);

    const toast = useRef<Toast>(null);

    const { session } = useSession();

    useEffect(() => {
        setModalHeader("Manage Transactions");
    }, []);

    const handleAmountChange = (value: any) => {
        if (selectedRefundItem) {
            if (value <= selectedRefundItem.amount) {
                setAmount(value);
            }
        } else {
            setAmount(value);
        }
    };

    const handleRefundClick = (data: any) => {
        const selectedItem = formData.find((x: any) => x.ref === data.ref);
        setSelectedRefundItem(selectedItem);
        setAmount(selectedItem.amount - (selectedItem.refundedAmount || 0));
    };

    const handleRefund = () => {
        if (selectedRefundItem?.ref) {
            postData(session, `transactions/refund/${selectedRefundItem.ref}`, { amount: amount }, null).then(refund => {

                const item = modalData.selectedDataItem.find((x: any) => x.ref === selectedRefundItem.ref);
                item.refundedAmount += amount || 0;

                modalData.closeModal({ severity: "success", summary: `Refunded successfully!` });
            }).catch(err => {
                if (toast.current) {
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                }
            });
        }
    };

    const customButtons = [{
        visibleDialog: true,
        icon: 'pi pi-code',
        onClick: handleRefundClick,
        id: "refund",
        selectableRow: true
    }];

    function shouldDisableButton(rowData: any, id: any) {
        if (id === "refund" && rowData.paymentType === "paymentLink") {
            return false;
        } else if (id === "refund") {
            return true;
        }
    }

    const shouldRemoveButton = (rowData: any, id: string) => {
        return true;
    };

    return (
        <div className="modal-container">
            <Toast ref={toast} />
            <div className="modal">
                <div className="modal-header">
                    <span className="modal-title">{modalHeader}</span>
                    <span className="modal-close" onClick={modalData.closeModal}>&times;</span>
                </div>
                <div className="flex">
                    <Table
                        data={formData}
                        type={type}
                        shouldDisableButton={shouldDisableButton}
                        shouldRemoveButton={shouldRemoveButton}
                        editButtonVisible={false}
                        addButtonVisible={false}
                        removeButtonVisible={false}
                        customButtons={customButtons}
                    />
                </div>
                <div className="flex">
                    <label className="text-label" htmlFor="amount">Amount</label>
                    <InputNumber
                        className="text-input-number refund"
                        inputId="currency-germany"
                        value={amount}
                        onValueChange={(e) => handleAmountChange(e.value || 0)}
                        max={selectedRefundItem ? selectedRefundItem.amount : undefined}
                        mode="currency"
                        currency="EUR" />
                </div>
                <div className="footer">
                    <button type="button" className="save-button" onClick={handleRefund}>Refund</button>
                    <button type="button" className="cancel-button" onClick={modalData.closeModal}>Cancel</button>
                </div>
            </div>
        </div>
    );
}
