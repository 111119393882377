
import { useSession } from './assets/auth/SessionContext';

import { deleteData, getData } from './services/api';
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import dayjs from "dayjs";

import { postData } from "./services/api";


const CreateTickets = () => {

    const { session } = useSession();

    const [data, setData] = useState<{ gender: Object, ticketTypes: [] }>();
    const [error, setError] = useState(null);
    const toast = useRef<Toast>(null);

    const [dateFrom, setDateFrom] = useState<Date>(new Date());
    const [dateTo, setDateTo] = useState<Date>(new Date(dayjs().add(1, "month").valueOf()));
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [selectedTicketType, setSelectedTicketType] = useState(null);
    const [selectedTicketTypeDropDown, setSelectedTicketTypeDropDown] = useState();
    const [quantity, setQuantity] = useState(0);
    const [maleQuantity, setMaleQuantity] = useState(0);
    const [femaleQuantity, setFemaleQuantity] = useState(0);
    const [othersQuantity, setOthersQuantity] = useState(0);

    const [saveDisabled, setSaveDisabled] = useState(false);

    interface Checkout {
        fullName: string;
        email: string;
        phoneNumber: string;
    }

    const [checkout, setCheckout] = useState<Checkout>({
        fullName: '',
        email: '',
        phoneNumber: ''
    });

    const dateFormat = 'dd-mm-yy';

    useEffect(() => {

        getData(session, `events`, `dateFrom=${dayjs(dateFrom).format("YYYY-MM-DD")}&dateTo=${dayjs(dateTo).format("YYYY-MM-DD")}&dropdown=true`).then(events => {

            const eventsForDropdown = events.map((x: { name: any; ref: any; }) => ({
                name: x.name,
                value: x.ref
            }));

            setEvents(eventsForDropdown);

        }).catch(err => {
            setError(err);
        });

    }, [dateFrom, dateTo, session, setError]);

    function setDate(fieldName: String, value: Date) {
        if (fieldName === "from" && value !== null) {
            let newDateTo = dateTo;
            newDateTo = dayjs(value).add(1, 'month').toDate();
            setDateFrom(value);
            setDateTo(newDateTo);
        }

        if (fieldName === "to" && value !== null) {
            let newDateFrom = dateFrom;
            newDateFrom = dayjs(value).add(-1, 'month').toDate();
            setDateTo(value);
            setDateFrom(newDateFrom);
        }
    }

    const onChangeEvent = (eventRef: any) => {
        setSelectedEvent(eventRef);

        getData(session, `events/${eventRef}/ticket-types`, `complimentary=true`).then(ticketTypes => {

            const ticketTypesForDropdown = ticketTypes.ticketTypes.map((x: { name: any; ref: any; }) => ({
                name: x.name,
                value: x.ref
            }));

            setTicketTypes(ticketTypesForDropdown);
            setData(ticketTypes);

        }).catch(err => {
            setError(err);
        });
    };

    const onChangeTicketType = (ticketTypeRef: any) => {

        setSelectedTicketType(ticketTypeRef);

        if (data) {
            const selected = data.ticketTypes.find((x: { ref: any; }) => x.ref === ticketTypeRef);

            if (selected) {
                setSelectedTicketTypeDropDown(selected);
            }
        }
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setCheckout({ ...checkout, [name]: value });
    };

    const validateEmail = (email: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    async function save() {
        try {

            if (!selectedTicketType) {
                throw new Error("Ticket type is required")
            }

            if (!checkout.fullName) {
                throw new Error("Full name is required")
            }

            if (!checkout.email) {
                throw new Error("E-Mail is required")
            }

            if (!validateEmail(checkout.email)) {
                throw new Error("E-Mail is not valid")
            }

            if (!quantity || quantity === 0) {
                throw new Error("Quantity is required and cannot be 0")
            }

            let addToCartOptions = {
                ticketTypeRef: selectedTicketType,
                isDeposit: false,
                isFullPayment: true,
                quantity: quantity
            } as any;

            if (data && data.gender) {

                const totalGenderQuantity = maleQuantity + femaleQuantity + othersQuantity;

                if (totalGenderQuantity !== quantity) {
                    throw new Error("Gender quantity and quantity of tickets does not match");
                }

                addToCartOptions.gender = {
                    male: maleQuantity,
                    female: femaleQuantity,
                    other: othersQuantity
                }
            }

            postData(session, `cart`, [addToCartOptions], null).then(addToCartData => {

                const checkoutOptions = {
                    fullName: checkout.fullName,
                    email: checkout.email,
                    phoneNumber: checkout.phoneNumber,
                    paymentType: "free"
                }

                postData(session, `cart/checkout`, checkoutOptions, null).then(addToCartData => {

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: addToCartData.message, detail: "" });
                    }

                    setQuantity(0);
                    setFemaleQuantity(0);
                    setMaleQuantity(0);
                    setOthersQuantity(0);
                    setCheckout({
                        fullName: '',
                        email: '',
                        phoneNumber: ''
                    });

                    setSaveDisabled(false);

                    return;

                }).catch(async err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }

                    await deleteData(session, `cart`, null);
                    setSaveDisabled(false);
                });

            }).catch(async err => {
                if (toast.current) {
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                }

                await deleteData(session, `cart`, null);
                setSaveDisabled(false);
            });

        } catch (error: any) {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: error.message });
            }

            setSaveDisabled(false);
        }
    }

    return (
        <div className="flex">
            <div className="table-container">

                <Toast ref={toast} />
                <div className="card flex flex-wrap gap-3 p-fluid select-dates">
                    <div className="flex1">
                        <label htmlFor="buttondisplay" className="font-bold block mb-2">
                            From
                        </label>
                        <Calendar dateFormat={dateFormat} className="calendar" value={dateFrom} onChange={(e) => setDate("from", e.value || new Date())} showIcon />
                    </div>
                    <div className="flex1">
                        <label htmlFor="buttondisplay" className="font-bold block mb-2">
                            To
                        </label>

                        <Calendar value={dateTo} dateFormat={dateFormat} className="calendar" onChange={(e) => setDate("to", e.value || new Date())} showIcon />
                    </div>
                    <div className="flex1 top-bar-filtering">
                        <label htmlFor="text-label" className="font-bold block mb-2">Select event</label>
                        <Dropdown
                            onChange={(e) => { onChangeEvent(e.value); }}
                            options={events}
                            optionLabel="name"
                            placeholder="Select event"
                            className="dropdown"
                            value={selectedEvent}
                        />
                    </div>
                    <div className="flex1 top-bar-filtering">
                        <label htmlFor="text-label" className="font-bold block mb-2">Select Ticket Type</label>
                        <Dropdown
                            onChange={(e) => { onChangeTicketType(e.value); }}
                            options={ticketTypes}
                            optionLabel="name"
                            placeholder="Select ticket type"
                            className="dropdown"
                            value={selectedTicketType}
                        />
                    </div>
                </div>

                <div className="create-ticket">
                    <div className="flex">
                        <div className="flex-1 flex py-3">
                            <label className="text-label" htmlFor="quantity">Quantity</label>
                            <InputNumber className="text-input-number" value={quantity} onValueChange={(e) => setQuantity(e.value || 0)} />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flex-1 flex py-3">
                            <label className="text-label" htmlFor="ref">Full Name</label>
                            <input
                                className="text-input"
                                type="text"
                                id="fullName"
                                name="fullName"
                                value={checkout.fullName}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex-1 flex py-3">
                            <label className="text-label" htmlFor="ref">E-Mail</label>
                            <input
                                className="text-input"
                                type="text"
                                id="email"
                                name="email"
                                value={checkout.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex-1 flex py-3">
                            <label className="text-label" htmlFor="ref">Phone Number</label>
                            <input
                                className="text-input"
                                type="text"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={checkout.phoneNumber}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    {data && data.gender && (
                        <div className="flex">

                            <div className="flex-1 flex py-3">
                                <label className="text-label" htmlFor="firstName">Male</label>
                                <InputNumber className="text-input-number" value={maleQuantity} onValueChange={(e) => setMaleQuantity(e.value || 0)} />
                            </div>

                            <div className="flex-1 flex py-3">
                                <label className="text-label" htmlFor="firstName">Female</label>
                                <InputNumber className="text-input-number" value={femaleQuantity} onValueChange={(e) => setFemaleQuantity(e.value || 0)} />
                            </div>

                            <div className="flex-1 flex py-3">
                                <label className="text-label" htmlFor="firstName">Others</label>
                                <InputNumber className="text-input-number" value={othersQuantity} onValueChange={(e) => setOthersQuantity(e.value || 0)} />
                            </div>

                        </div>
                    )}
                </div>

                <div className="footer">
                    <button disabled={saveDisabled} className="save-button" type="button" onClick={() => {
                        setSaveDisabled(true);
                        save();
                    }
                    }>Create</button>
                </div>

            </div>
        </div>
    )
}

export default CreateTickets; 