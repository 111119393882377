
import Table from "./components/Table";
import DeviceUserModal from './components/DeviceUserModal';

import { getData, deleteData } from './services/api';
import { useSession } from './assets/auth/SessionContext';
import { useEffect, useState } from "react";


const DeviceUsers = () => {
    const { session } = useSession();

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [type] = useState("deviceUsers")

    async function remove(ref: string) {
        try {
            await deleteData(session, `device-users/${ref}`, null)
            return { success: true, message: "User removed" };
        } catch (err: any) {
            return { success: false, message: err.message };
        }
    }

    useEffect(() => {

        getData(session, `device-users`, null).then(userData => {
            getData(session, `teams`, null).then(teamsData => {

                for (const deviceUser of userData) {
                    deviceUser.teamsString = teamsData.filter((x: { ref: string; }) => deviceUser.teams.find((y: string) => y === x.ref) != null).map((x: { name: string; }) => x.name).join(", ")
                }

                setData(userData)
                setIsLoading(false);
            }).catch(err => {
                setError(err);
                setIsLoading(false);
            });
        }).catch(err => {
            setError(err);
            setIsLoading(false);
        });
    }, []);

    if (isLoading || (!data && !error)) {
        // Render loading indicator or placeholder while data is being fetched
        return (
            <div className="flex">
                <div className="table-container">

                </div>
            </div>
        )
    }

    return (
        <div className="flex">
            <div className="table-container">
                <Table data={data} error={error} type={type} remove={remove} modal={<DeviceUserModal />} />
            </div>
        </div>
    )
}

export default DeviceUsers; 