
import Table from "./components/Table";
// import RoleModal from './components/RoleModal';

import { getData, deleteData, postData } from './services/api';
import { useSession } from './assets/auth/SessionContext';
import React, { useEffect, useState, useRef } from 'react';
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import dayjs from "dayjs";
import datePicker from "./utils/date-picker";
import InfoModal from "./components/ListTicketInfoModal";
import models from './utils/models';
import { classNames } from "primereact/utils";
import { PlusCircle } from "lucide-react";

import CreateExternalTicketModal from './components/CreateExternalTicketModal';

interface Event {
    name: string | undefined;
    ref: string;
}

interface Ticket {
    ref: string;
    isScanned: boolean;
    transactions: any;
    email: string;
    deletedAt: Date
}

const ListExternalTickets = () => {
    const { session } = useSession();

    const [data, setData] = useState<Ticket[]>([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const toast = useRef<Toast>(null);
    const [Modal, setModal] = useState(<CreateExternalTicketModal />);
    const [dateFrom, setDateFrom] = useState<Date>(new Date());
    const [dateTo, setDateTo] = useState<Date>(dayjs().add(1, "month").toDate());
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const [events, setEvents] = useState<Event[]>([]);
    const [selectedRemoveItem, setSelectedRemoveItem] = useState<string>("");
    const [removeIsVisible, setRemoveIsVisible] = useState(false);
    const [selectedInfoItem, setSelectedInfoItem] = useState<string | null>(null);
    const [infoModalIsVisible, setInfoModalIsVisible] = useState(false);
    const [selectedScanItem, setSelectedScanItem] = useState<string | null>(null);
    const [scanIsVisible, setScanIsVisible] = useState<{ scan: boolean }>({ scan: false });
    const [isAddTicketDisabled, setIsAddTicketDisabled] = useState(true);
    const [visibleDialog, setVisibleDialog] = useState(false);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    });

    const dependencies = [selectedEvent];

    const dateFormat = 'dd-mm-yy';

    useEffect(() => {
        fetchEvents();
    }, [dateFrom, dateTo, session]);

    const fetchEvents = () => {
        getData(session, `events`, `dateFrom=${dayjs(dateFrom).format("YYYY-MM-DD")}&dateTo=${dayjs(dateTo).format("YYYY-MM-DD")}&dropdown=true`)
            .then((events: Event[]) => {
                setEvents(events);
                setIsLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setIsLoading(false);
            });
    };

    const refreshTable = () => {
        setLazyParams({ ...lazyParams });
    };

    const onDateChange = (fieldName: string, value: Date | null) => {
        datePicker.max1Month({ fieldName, value, from: dateFrom, to: dateTo, setFrom: setDateFrom, setTo: setDateTo });

        setSelectedEvent(null);
    };

    const onEventChange = (ref: string) => {

        const eventSelectedName = events.find(x => x.ref === ref);

        setData([]);
        setSelectedEvent({ ref, name: eventSelectedName?.name });
    };

    const fetchTicketsLazy = async (lazyParams: any) => {

        if (selectedEvent) {

            if (lazyParams.export) {
                const result = await getData(session, `tickets/external/list/${selectedEvent.ref}`, `export=true`);
                return result
            }

            const { page, rows } = lazyParams;
            const result = await getData(session, `tickets/external/list/${selectedEvent.ref}`, `page=${page}&rows=${rows}`);
            setData(result.items);
            setIsAddTicketDisabled(false);
            return result;
        }

        return {};
    };

    const shouldDisableButton = (rowData: Ticket, id: string) => {
        if (id === "scan") {
            return !(rowData.isScanned === false && !rowData.deletedAt);
        }

        if (id === "remove" && rowData.deletedAt) {
            return true;
        }

        return false;
    };

    const handleRemoveClick = (data: Ticket) => {
        setSelectedRemoveItem(data.ref);
        setRemoveIsVisible(true);
    };

    const handleInfoClick = (data: Ticket) => {
        setSelectedInfoItem(data.ref);
        setInfoModalIsVisible(true);
    }

    const handleRemoveHide = () => {
        setRemoveIsVisible(false);
    }

    const handleClick = (id: string, ref: string) => setScanIsVisible({ ...scanIsVisible, [id]: true });
    const handleScanHide = () => setScanIsVisible({ ...scanIsVisible, scan: false });

    const remove = async (ref: any) => {
        try {
            console.log("Ref: " + ref)
            await deleteData(session, `tickets/${ref}`, null);

            return { success: true, message: "Ticket removed" };
        } catch (err: any) {
            return { success: false, message: err.message };
        }
    };

    const confirmRemove = async () => {
        setRemoveIsVisible(false);
        const removeResult = await remove(selectedRemoveItem);

        if (removeResult.success === true && toast.current) {
            refreshTable();
            toast.current.show({ severity: 'success', summary: "Removed successfully", detail: "" });
        } else if (removeResult.success === false && toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: removeResult.message });
        }
    };

    const handleTicketListInfoHide = (update: any | null) => {
        setInfoModalIsVisible(false);
    }

    const customRowBodyTemplate = (rowData: Ticket) => {
        if (rowData.isScanned != null) {
            return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.isScanned, 'text-red-500 pi-times-circle': !rowData.isScanned })}></i>;
        }
    };

    const shouldRemoveButton = (rowData: Ticket, id: string) => {

        if (id === "info" && !rowData.deletedAt) {
            return false;
        }

        return true;
    };

    const handleScanClick = (data: Ticket) => {
        setSelectedScanItem(data.ref);
        handleClick('scan', data.ref);
    };

    const confirmScan = () => {
        if (selectedScanItem && selectedEvent) {
            postData(session, `tickets/scan/${selectedScanItem}`, { eventRef: selectedEvent.ref }, null)
                .then(() => {
                    refreshTable();
                    setScanIsVisible({ ...scanIsVisible, scan: false });
                })
                .catch(err => toast.current?.show({ severity: 'error', summary: 'Error Message', detail: err.message }));
        }
    };

    const openModal = () => {
        setVisibleDialog(true);
    };

    const closeModal = async (update: any | null) => {

        setVisibleDialog(false);

        if (toast && toast.current && !update.success && update.message) {
            toast.current.show({ severity: 'error', summary: update.message, detail: "" });
        }

        if (update.newRecords) {

            const newRecords = update.newRecords;

            setData([...data, ...newRecords]);

            refreshTable();
        }
    };

    if (isLoading || (!data && !error)) {
        // Render loading indicator or placeholder while data is being fetched
        return (
            <div className="flex">
                <div className="table-container">

                </div>
            </div>
        )
    }

    const customButtons = [
        { icon: 'pi pi-trash', onClick: handleRemoveClick, id: "remove" },
        { icon: 'pi pi-bookmark', onClick: handleScanClick, id: "scan" },
        { icon: "pi pi-info-circle", onClick: handleInfoClick, id: "info", style: { color: "red" } },
    ];

    const customModals = [
        {
            modal: (
                <ConfirmDialog
                    visible={removeIsVisible}
                    onHide={handleRemoveHide}
                    message="Are you sure you want to delete this item?"
                    header="Confirmation"
                    icon="pi pi-exclamation-triangle"
                    accept={confirmRemove}
                    reject={handleRemoveHide}
                    acceptClassName="confirm-dialog-yes"
                    rejectClassName="confirm-dialog-no"
                />
            )
        },
        {
            modal: (
                <ConfirmDialog
                    visible={scanIsVisible.scan}
                    onHide={handleScanHide}
                    message="Are you sure you want to scan this item?"
                    header="Scan Ticket"
                    icon="pi pi-exclamation-triangle"
                    accept={confirmScan}
                    reject={handleScanHide}
                    acceptClassName="confirm-dialog-yes"
                    rejectClassName="confirm-dialog-no"
                />
            )
        },
        {
            modal: (
                <Dialog visible={infoModalIsVisible} onHide={() => { }}>
                    <InfoModal
                        selectedDataItem={data.find(item => item.ref === selectedInfoItem)}
                        closeModal={handleTicketListInfoHide}
                    />
                </Dialog>
            )
        }
    ];

    return (
        <div className="flex">
            <Toast ref={toast} />
            <div className="table-container">
                <div>
                    <div className="card flex flex-wrap gap-3 p-fluid select-dates">
                        <div className="flex1">
                            <label htmlFor="buttondisplay" className="font-bold block mb-2">From</label>
                            <Calendar dateFormat={dateFormat} className="calendar" value={dateFrom} onChange={(e) => onDateChange("from", e.value as Date)} showIcon />
                        </div>
                        <div className="flex1">
                            <label htmlFor="buttondisplay" className="font-bold block mb-2">To</label>
                            <Calendar value={dateTo} dateFormat={dateFormat} className="calendar" onChange={(e) => onDateChange("to", e.value as Date)} showIcon />
                        </div>
                        <div className="flex1 top-bar-filtering">

                        </div>
                        <div className="flex1 top-bar-filtering">
                            <label htmlFor="text-label" className="font-bold block mb-2">Select event</label>
                            <Dropdown
                                onChange={(e) => onEventChange(e.value as string)}
                                options={events.map(event => ({ label: event.name, value: event.ref }))}
                                placeholder="Select event"
                                className="dropdown"
                                value={selectedEvent?.ref || ""}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex">
                    <div className="table-container">
                        <Table
                            type="externalTickets"
                            shouldDisableButton={shouldDisableButton}
                            shouldRemoveButton={shouldRemoveButton}
                            remove={remove}
                            editButtonVisible={false}
                            removeButtonVisible={false}
                            addButtonVisible={false}
                            customButtons={customButtons}
                            customModals={customModals}
                            customRowBodyTemplate={customRowBodyTemplate}
                            exportButtonVisible={true}
                            map={models.externalTicketsMap()}
                            loadFunction={fetchTicketsLazy}
                            lazy={true}
                            dependencies={dependencies}
                            lazyParams={lazyParams}
                            setLazyParams={setLazyParams}
                            showFilters={false}
                            refreshTable={refreshTable}
                        />
                    </div>
                </div>
                <div className='add-container'>
                    <div className='flex' >
                        <Button disabled={isAddTicketDisabled} className="add-button" type="button" tooltip="Add Tickets"
                            onClick={() => { setModal(<CreateExternalTicketModal />); openModal() }}
                            icon={<PlusCircle size={80} />}>
                        </Button>
                    </div>
                </div>

                <Dialog visible={visibleDialog} onHide={() => { }}>
                    <div>
                        <div>
                            {React.cloneElement(Modal, { event: selectedEvent, closeModal })}
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

export default ListExternalTickets; 