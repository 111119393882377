import { Toast } from "primereact/toast";
import { useSession } from "../assets/auth/SessionContext";
import { useEffect, useRef, useState } from "react";
import { getData, postData, putData } from "../services/api";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Image } from 'primereact/image';
import { PickList } from "primereact/picklist";
import { upload } from "../services/images";

export default function TicketTypeModal(modalData: any) {

    const [formData, setFormData] = useState(modalData.selectedDataItem);
    const [depositEditable, setDepositEditable] = useState(false);
    const [modalHeader, setModalHeader] = useState("");

    const [statuses, setStatuses] = useState<{ name: string; value: string; }[]>([]);
    const [ticketTypeGroups, setTicketTypeGroups] = useState<{ name: string; value: string; }[]>([]);
    const [teamSellers, setTeamSellers] = useState<{ name: string; value: string; }[]>([]);
    const [selectedTeams, setSelectedTeams] = useState<{ name: string; value: string; }[]>([]);
    const [deviceUserSellers, setDeviceUserSellers] = useState<{ name: string; value: string; }[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<{ name: string; value: string; }[]>([]);
    const [allDeviceUsers, setAllDeviceUsers] = useState<{ teams: [string]; value: string; }[]>([]);
    const [price, setPrice] = useState(0);
    const [vat, setVat] = useState(0);
    const [active, setActive] = useState(false);
    const [closeBoysTickets, setCloseBoysTickets] = useState(false);
    const [ticketCapacity, setTicketCapacity] = useState(0);
    const [maxTicketsInBasket, setMaxTicketsInBasket] = useState(20);
    const [levelOrder, setLevelOrder] = useState(0);
    const [bookingFee, setBookingFee] = useState(0);
    const [depositPercentage, setDepositPercentage] = useState(0);
    const [isDepositAllowed, setIsDepositAllowed] = useState(false);
    const [isAvailableForWebsite, setIsAvailableForWebsite] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedTicketTypeGroup, setSelectedTicketTypeGroup] = useState(null);
    const [imageBase64, setImageBase64] = useState("");
    const [commission, setCommission] = useState(0);

    const [saveDisabled, setSaveDisabled] = useState(false);

    const eventRef = formData.eventRef;
    let eventName = formData.eventName;

    const toast = useRef<Toast>(null);

    const { session } = useSession();

    const statusesArray = [
        {
            name: "Open",
            value: "OPEN"
        },
        {
            name: "Close",
            value: "CLOSE"
        },
        {
            name: "Sold Out",
            value: "SOLD_OUT"
        }
    ];

    function save(data: any) {
        try {

            if (!data.name) {
                throw new Error("Name is required")
            }

            if (price === null) {
                throw new Error("Price is required")
            }

            if (vat === null) {
                throw new Error("VAT is required")
            }

            if (!selectedStatus) {
                throw new Error("Status is required")
            }

            if (!bookingFee === null) {
                throw new Error("Booking fee is required")
            }

            if (!eventRef) {
                throw new Error("Event is required")
            }

            if (!imageBase64) {
                throw new Error("Image is required")
            }

            if (!ticketCapacity) {
                throw new Error("Tickets available is required")
            }

            if (!selectedTicketTypeGroup) {
                throw new Error("A ticket type group is required")
            }

            if (isDepositAllowed) {
                if (!depositPercentage) {
                    throw new Error("Deposit percentage is required")
                }
            }

            let ticketType = {
                ref: data.ref,
                name: data.name,
                description: data.description,
                price: price,
                vatPercentage: vat,
                ticketCapacity: ticketCapacity,
                status: selectedStatus,
                eventRef: eventRef,
                image: imageBase64,
                bookingFee: bookingFee,
                active: active,
                isDepositAllowed: isDepositAllowed || false,
                depositPercentage: depositPercentage || 0,
                ticketTypeGroupRef: selectedTicketTypeGroup,
                maxTicketsInBasket: maxTicketsInBasket,
                levelOrder: levelOrder,
                teamSellers: selectedTeams.map(x => x.value),
                deviceUserSellers: selectedUsers.map(x => x.value),
                isAvailableForWebsite: isAvailableForWebsite,
                closeBoysTickets: closeBoysTickets,
                commission: commission || 0
            } as any;


            if (modalData.mode === "update") {
                putData(session, `ticket-types/${ticketType.ref}`, ticketType, null).then(updateTicketTypeData => {


                    modalData.closeModal({ toast: true, message: `${ticketType.name} updated`, updateRecord: ticketType, mode: "ticketType" });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'Ticket type updated', detail: "" });
                    }

                    setSaveDisabled(false);
                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }

                    setSaveDisabled(false);
                });
            } else if (modalData.mode === "create") {

                postData(session, `ticket-types`, ticketType, null).then(newRoleData => {

                    modalData.closeModal({ toast: true, message: `${ticketType.name} created`, newRecord: newRoleData, mode: "ticketType" });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'Ticket type created', detail: "" });
                    }

                    setSaveDisabled(false);
                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }

                    setSaveDisabled(false);
                });
            }
        } catch (error: any) {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: error.message });
            }

            setSaveDisabled(false);
        }
    }

    function dataPick(options: any) {
        const { selected, data } = options;

        const selectedList = [];

        for (const ref of selected || []) {

            const index = data.findIndex((x: { value: any; }) => x.value === ref);

            if (index > -1) {
                const item = data[index];
                selectedList.push(item);
                data.splice(index, 1);
            }
        }

        return { selectedList, data };
    }

    const customBase64Uploader = async (event: any) => {
        const base64data = await upload(event);

        if (base64data) {
            setImageBase64(base64data.toString());
        }
    };

    useEffect(() => {
        if (isDepositAllowed === true) {
            setDepositEditable(true);
        } else {
            setDepositEditable(false);
            setDepositPercentage(0);
        }

    }, [isDepositAllowed, setIsDepositAllowed]);

    useEffect(() => {
        if (modalData.mode === "create") {
            setModalHeader("Creating Ticket Type")
        } else {
            setModalHeader("Updating Ticket Type")
        }

        setStatuses(statusesArray);
        setTicketTypeGroups(formData.ticketTypeGroups.map((x: any) => ({ name: x.name, value: x.ref })));

        getData(session, `teams`, null).then(teamsData => {

            getData(session, `device-users`, null).then(deviceUsersData => {

                teamsData = teamsData.map((x: any) => ({ name: x.name, value: x.ref }));
                const newDeviceUserData = deviceUsersData.map((x: any) => ({ name: `${x.firstName} ${x.lastName}`, value: x.ref }));

                setAllDeviceUsers(deviceUsersData.map((x: any) => ({ teams: x.teams, value: x.ref })));

                if (modalData.mode === "update") {

                    getData(session, `ticket-types/${formData.ref}`, null).then(ticketTypeData => {

                        const teamsDataPick = dataPick({ data: teamsData, selected: ticketTypeData.teamSellers });
                        setSelectedTeams(teamsDataPick.selectedList);
                        setTeamSellers(teamsDataPick.data);

                        const deviceUserDataPick = dataPick({ data: newDeviceUserData, selected: ticketTypeData.deviceUserSellers });
                        setSelectedUsers(deviceUserDataPick.selectedList);
                        setDeviceUserSellers(deviceUserDataPick.data);

                        setFormData(ticketTypeData);

                        setSelectedStatus(ticketTypeData.status);
                        setSelectedTicketTypeGroup(ticketTypeData.ticketTypeGroupRef);

                        setPrice(ticketTypeData.price);
                        setVat(ticketTypeData.vatPercentage);
                        setTicketCapacity(ticketTypeData.ticketCapacity);
                        setMaxTicketsInBasket(ticketTypeData.maxTicketsInBasket);
                        setBookingFee(ticketTypeData.bookingFee);
                        setIsDepositAllowed(ticketTypeData.isDepositAllowed);
                        setDepositPercentage(ticketTypeData.depositPercentage);
                        setActive(ticketTypeData.active);
                        setImageBase64(ticketTypeData.image);
                        setLevelOrder(ticketTypeData.levelOrder);
                        setIsAvailableForWebsite(ticketTypeData.isAvailableForWebsite);
                        setCloseBoysTickets(ticketTypeData.closeBoysTickets);
                        setCommission(ticketTypeData.commission);
                    });
                } else {
                    const teamsDataPick = dataPick({ data: teamsData, selected: [] });
                    const deviceUserDataPick = dataPick({ data: newDeviceUserData, selected: [] });
                    setTeamSellers(teamsDataPick.data);
                    setDeviceUserSellers(deviceUserDataPick.data);
                    setSelectedTeams([]);
                    setSelectedUsers([]);
                }
            });
        });
    }, []);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const itemTemplate = (item: any) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                <span className="font-bold text-900">{item.name}</span>
            </div>
        );
    };

    const onChangeTeams = (event: any) => {

        for (const team of event.target) {
            const teamFound = selectedTeams.find(x => x.value === team.value);

            // if item not found then it means that its a new one in the list
            if (!teamFound) {
                // select device users in this team

                const deviceUsersInTeam = allDeviceUsers.filter(x => x.teams.indexOf(team.value) > -1);

                for (const deviceUser of deviceUsersInTeam) {
                    const index = deviceUserSellers.findIndex(x => x.value === deviceUser.value);

                    if (index > -1) {
                        const user = deviceUserSellers[index];
                        selectedUsers.push({ name: user?.name, value: user?.value })

                        deviceUserSellers.splice(index, 1)

                        setSelectedUsers(selectedUsers);
                        setDeviceUserSellers(deviceUserSellers);
                    }
                }
            }
        }

        for (const team of selectedTeams) {
            const teamFound = event.target.find((x: { value: string; }) => x.value === team.value);

            // if item not found then it means that the team has been removed from the list
            if (!teamFound) {
                // move device users to not selected

                const deviceUsersInTeam = allDeviceUsers.filter(x => x.teams.indexOf(team.value) > -1);

                for (const deviceUser of deviceUsersInTeam) {
                    const index = selectedUsers.findIndex(x => x.value === deviceUser.value);

                    if (index > -1) {
                        const user = selectedUsers[index];
                        deviceUserSellers.push({ name: user?.name, value: user?.value })

                        selectedUsers.splice(index, 1)

                        setSelectedUsers(selectedUsers);
                        setDeviceUserSellers(deviceUserSellers);
                    }
                }
            }
        }

        setTeamSellers(event.source);
        setSelectedTeams(event.target);
    };

    const onChangeUsers = (event: any) => {
        setDeviceUserSellers(event.source);
        setSelectedUsers(event.target);
    };

    return (
        <div className="modal-container">

            <Toast ref={toast} />
            <div className="modal ">
                <div className="modal-header">
                    <span className="modal-title">{modalHeader}</span>
                    <span className="modal-close" onClick={modalData.closeModal}>&times;</span>
                </div>

                {/* row 1 */}
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Event</label>
                        <input
                            className="text-input"
                            readOnly={true}
                            type="text"
                            id="event"
                            name="event"
                            value={eventName}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="name">Name</label>
                        <input
                            className="text-input"
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="price">Active</label>
                        <div className="input-switch-min-width">
                            <InputSwitch
                                checked={active}
                                onChange={(e) => setActive(e.value)}
                            />
                        </div>
                    </div>
                </div>

                {/* row 2 */}
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="price">Price</label>
                        <InputNumber inputId="currency-germany" className="text-input-number" value={price} onValueChange={(e) => setPrice(e.value || 0)} mode="currency" currency="EUR" />

                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="name">VAT</label>
                        <InputNumber className="text-input-number" min={0} max={100} value={vat} onValueChange={(e) => setVat(e.value || 0)} suffix="%" />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ticketCapacity">Tickets Available</label>
                        <InputNumber className="text-input-number" value={ticketCapacity} onValueChange={(e) => setTicketCapacity(e.value || 0)} />
                    </div>
                </div>

                {/* row 3 */}
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="price">Booking Fee</label>
                        <InputNumber className="text-input-number" inputId="currency-germany" value={bookingFee} onValueChange={(e) => setBookingFee(e.value || 0)} mode="currency" currency="EUR" />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="isDepositAllowed">Are Deposits Allowed?</label>
                        <div className="input-switch-min-width">
                            <InputSwitch
                                checked={isDepositAllowed}
                                onChange={(e) => setIsDepositAllowed(e.value)}
                            />
                        </div>
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="name">Deposit %</label>
                        <InputNumber readOnly={!depositEditable} className="text-input-number" min={0} max={100} value={depositPercentage} onValueChange={(e) => setDepositPercentage(e.value || 0)} suffix="%" />
                    </div>
                </div>

                {/* row 4 */}
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="status">Status</label>
                        <Dropdown
                            onChange={(e) => { setSelectedStatus(e.value); }}
                            options={statuses}
                            optionLabel="name"
                            placeholder="Select status"
                            className="dropdown"
                            value={selectedStatus} />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="ticketTypeGroupRef">Group</label>
                        <Dropdown
                            onChange={(e) => { setSelectedTicketTypeGroup(e.value); }}
                            options={ticketTypeGroups}
                            optionLabel="name"
                            placeholder="Select ticket type group"
                            className="dropdown"
                            value={selectedTicketTypeGroup} />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="maxTicketsInBasket">Max tickets in basket</label>
                        <InputNumber className="text-input-number" value={maxTicketsInBasket} onValueChange={(e) => setMaxTicketsInBasket(e.value || 0)} />
                    </div>
                </div>

                {/* row 5 */}
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="name">Commission - €</label>
                        <InputNumber className="text-input-number" min={0} value={commission} onValueChange={(e) => setCommission(e.value || 0)} />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="isAvailableForWebsite">Is available for website?</label>
                        <div className="input-switch-min-width">
                            <InputSwitch
                                checked={isAvailableForWebsite}
                                onChange={(e) => setIsAvailableForWebsite(e.value)}
                            />
                        </div>
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="closeBoysTickets">Close boys</label>
                        <div className="input-switch-min-width">
                            <InputSwitch
                                checked={closeBoysTickets}
                                onChange={(e) => setCloseBoysTickets(e.value)}
                            />
                        </div>
                    </div>
                </div>

                {/* row 6 */}
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="description">Description</label>
                        <textarea
                            className="text-input-multiline"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 ">

                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">

                    </div>
                </div>

                {/* row 7 */}
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-2 pick-list-container">
                        <label className="text-label-teams" htmlFor="Teams" >Teams</label><br />
                        <PickList dataKey="value"
                            source={teamSellers}
                            target={selectedTeams}
                            onChange={onChangeTeams}
                            itemTemplate={itemTemplate}
                            // breakpoint="1280px"
                            filter filterBy="name" sourceFilterPlaceholder="Search" targetFilterPlaceholder="Search"
                            sourceHeader="Available" targetHeader="Selected" sourceStyle={{ height: '24rem' }} targetStyle={{ height: '24rem' }} />
                    </div>

                    <div className="flex-1 flex m-2 px-5 py-2 pick-list-container">
                        <label className="text-label-teams" htmlFor="Teams" >Users</label><br />
                        <PickList dataKey="value"
                            source={deviceUserSellers}
                            target={selectedUsers}
                            onChange={onChangeUsers}
                            itemTemplate={itemTemplate}
                            // breakpoint="1280px"
                            filter filterBy="name" sourceFilterPlaceholder="Search" targetFilterPlaceholder="Search"
                            sourceHeader="Available" targetHeader="Selected" sourceStyle={{ height: '24rem' }} targetStyle={{ height: '24rem' }} />
                    </div>
                </div>


                {/* row 8 */}
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 image-container">
                        <label className="text-label" htmlFor="level">Image</label>

                        <Image
                            src={imageBase64}
                            zoomSrc={imageBase64}
                            alt="Image"
                            width="80"
                            height="60"
                            preview />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 ">

                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">

                    </div>
                </div>

                {/* row 9 */}
                <div className="flex">
                    <div className="flex-1 uploader">
                        <FileUpload name="demo[]" accept="image/*" customUpload uploadHandler={customBase64Uploader} maxFileSize={1000000} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                    </div>

                </div>

                <div className="footer">
                    <button disabled={saveDisabled} className="save-button" type="button" onClick={() => {
                        setSaveDisabled(true);
                        save(formData);
                    }
                    }>Save</button>
                    <button type="button" className="cancel-button" onClick={modalData.closeModal}>Cancel</button>
                </div>
            </div>
        </div>
    );
}