import { Toast } from "primereact/toast";
import { useSession } from "../assets/auth/SessionContext";
import { useEffect, useRef, useState } from "react";
import { getData, postData, putData } from "../services/api";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";

export default function PermissionModal(modalData: any) {

    const [formData, setFormData] = useState(modalData.selectedDataItem);
    const [modalHeader, setModalHeader] = useState("");
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedRouteResource, setSelectedRouteResource] = useState(null);
    const [saveDisabled, setSaveDisabled] = useState(false);

    const [roles, setRoles] = useState([]);
    const [routeResources, setRouteResources] = useState([]);

    const toast = useRef<Toast>(null);

    const { session } = useSession();

    function save(data: any) {
        try {

            if (!data.name) {
                setSaveDisabled(false);
                throw new Error("Name is required")
            }

            if (!selectedRoles || (selectedRoles && selectedRoles.length === 0)) {
                setSaveDisabled(false);
                throw new Error("At least 1 role needs to be selected")
            }

            if (!selectedRouteResource) {
                setSaveDisabled(false);
                throw new Error("Route resource is required")
            }

            let permission = {
                ref: data.ref,
                name: data.name,
                routeResourceRef: selectedRouteResource,
                roles: selectedRoles
            } as any;


            if (modalData.mode === "update") {
                putData(session, `permissions/${permission.ref}`, permission, null).then(updatePermissionData => {

                    modalData.closeModal({ toast: true, message: `${permission.name} updated`, updateRecord: permission });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'Permission created', detail: "" });
                    }

                    setSaveDisabled(false);
                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }

                    setSaveDisabled(false);
                });
            } else if (modalData.mode === "create") {

                postData(session, `permissions`, permission, null).then(newPermissionData => {

                    modalData.closeModal({ toast: true, message: `${permission.name} created`, newRecord: newPermissionData });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'Permission created', detail: "" });
                    }

                    setSaveDisabled(false);
                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }

                    setSaveDisabled(false);
                });
            }
        } catch (error: any) {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: error.message });
            }

            setSaveDisabled(false);
        }
    }

    useEffect(() => {
        if (modalData.mode === "create") {
            setModalHeader("Creating Permission")
        } else {
            setModalHeader("Updating Permission")
        }

        getData(session, `roles`, null).then(roles => {

            const setRolesForMultiSelect = roles.map((x: { name: any; ref: any }) => ({
                name: x.name,
                value: x.ref
            }));

            setRoles(setRolesForMultiSelect)

            getData(session, `route-resources`, null).then(routeResources => {

                const routeResourcesForDropdown = routeResources.map((x: { name: any; ref: any; }) => ({
                    name: x.name,
                    value: x.ref
                }));

                setRouteResources(routeResourcesForDropdown);
            });
        });
    }, []);

    useEffect(() => {
        if (roles && roles.length > 0) {
            setSelectedRoles(formData.roles);
        }
    }, [formData.roles, roles]);

    useEffect(() => {
        if (routeResources && routeResources.length > 0) {
            setSelectedRouteResource(formData.routeResourceRef);
        }
    }, [formData.routeResourceRef, routeResources, setSelectedRouteResource]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return (
        <div className="modal-container">

            <Toast ref={toast} />
            <div className="modal ">
                <div className="modal-header">
                    <span className="modal-title">{modalHeader}</span>
                    <span className="modal-close" onClick={modalData.closeModal}>&times;</span>
                </div>

                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="name">Name</label>
                        <input
                            className="text-input"
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Route Resources</label>
                        <Dropdown
                            onChange={(e) => { setSelectedRouteResource(e.value); }}
                            options={routeResources}
                            optionLabel="name"
                            placeholder="Select route resource"
                            className="dropdown"
                            value={selectedRouteResource}
                            filter />
                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="level">Roles</label>
                        <MultiSelect value={selectedRoles} onChange={(e) => setSelectedRoles(e.value)} options={roles} optionLabel="name"
                            filter placeholder="Select roles" className="dropdown" />
                    </div>
                </div>

                <div className="footer">
                    <button disabled={saveDisabled} className="save-button" type="button" onClick={() => {
                        setSaveDisabled(true);
                        save(formData);
                    }
                    }>Save</button>
                    <button type="button" className="cancel-button" onClick={modalData.closeModal}>Cancel</button>
                </div>
            </div>
        </div>
    );
}