import { Toast } from "primereact/toast";
import { Image } from 'primereact/image';
import { FileUpload } from 'primereact/fileupload';
import { useSession } from "../assets/auth/SessionContext";
import { useEffect, useRef, useState } from "react";
import { getData, postData, putData } from "../services/api";
import { upload } from "../services/images";

export default function EventCategoryModal(modalData: any) {

    const [formData, setFormData] = useState(modalData.selectedDataItem);
    const [abbreviationEditable, setAbbreviationEditable] = useState(false);
    const [copyButtonEnabled, setCopyButtonEnabled] = useState<boolean>(true);
    const [generateHTML, setGenerateHTML] = useState("");
    const [imageBase64, setImageBase64] = useState("");
    const [modalHeader, setModalHeader] = useState("");

    const [saveDisabled, setSaveDisabled] = useState(false);

    const toast = useRef<Toast>(null);

    const { session } = useSession();

    const customBase64Uploader = async (event: any) => {

        const base64data = await upload(event);

        if (base64data) {
            setImageBase64(base64data.toString());
        }
    };

    function generate() {

        getData(session, `event-categories/generate-html/${formData.ref}`, "").then(data => {
            setCopyButtonEnabled(false);
            setGenerateHTML(data);
        });
    }

    function save(data: any) {
        try {

            if (!data.ref) {
                throw new Error("Abbreviation is required")
            }

            if (!data.name) {
                throw new Error("Name is required")
            }

            if (!imageBase64) {
                throw new Error("Image is required")
            }

            let eventCategory = {
                ref: data.ref,
                name: data.name,
                image: imageBase64
            } as any;


            if (modalData.mode === "update") {
                putData(session, `event-categories/${eventCategory.ref}`, eventCategory, null).then(updateEventCategoryData => {

                    modalData.closeModal({ toast: true, message: `${eventCategory.name} updated`, updateRecord: eventCategory });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'Event category created', detail: "" });
                    }

                    setSaveDisabled(false);
                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }

                    setSaveDisabled(false);
                });
            } else if (modalData.mode === "create") {

                postData(session, `event-categories`, eventCategory, null).then(newRoleData => {

                    modalData.closeModal({ toast: true, message: `${eventCategory.name} created`, newRecord: newRoleData });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'Event category created', detail: "" });
                    }

                    setSaveDisabled(false);
                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }

                    setSaveDisabled(false);
                });
            }
        } catch (error: any) {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: error.message });
            }

            setSaveDisabled(false);
        }
    }

    useEffect(() => {
        if (modalData.mode === "create") {
            setAbbreviationEditable(true)
            setModalHeader("Creating Event category")
        } else {
            setAbbreviationEditable(false)
            setModalHeader("Updating Event category")
        }

        setImageBase64(formData.image);
    }, []);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return (
        <div className="modal-container">

            <Toast ref={toast} />
            <div className="modal ">
                <div className="modal-header">
                    <span className="modal-title">{modalHeader}</span>
                    <span className="modal-close" onClick={modalData.closeModal}>&times;</span>
                </div>

                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Abbreviation</label>
                        <input
                            className="text-input"
                            readOnly={!abbreviationEditable}
                            type="text"
                            id="ref"
                            name="ref"
                            value={formData.ref}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="name">Name</label>
                        <input
                            className="text-input"
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 image-container">
                        <label className="text-label" htmlFor="level">Image</label>

                        <Image
                            src={imageBase64}
                            zoomSrc={imageBase64}
                            alt="Image"
                            width="80"
                            height="60"
                            preview />
                    </div>
                </div>

                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Website HTML</label>
                        <textarea
                            className="text-input-multiline"
                            id="generateHTML"
                            name="generateHTML"
                            value={generateHTML}
                            readOnly
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 table-header" style={{ flexDirection: "column", justifyContent: "space-between" }}>
                        <button
                            className="save-button p-button p-component"
                            style={{ height: "44px", width: "100px", textAlign: "center" }}
                            type="button"
                            onClick={() => {
                                generate();
                            }}
                        >
                            <span style={{ width: "100%" }}> Generate</span>

                        </button>

                        <button
                            disabled={copyButtonEnabled}
                            className="save-button p-button p-component copy-button"
                            style={{ height: "44px", width: "100px", textAlign: "center" }}
                            type="button"
                            onClick={() => {
                                navigator.clipboard.writeText(generateHTML);
                            }}
                        >
                            <span style={{ width: "100%" }}> Copy</span>

                        </button>
                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3">

                    </div>
                </div>

                <div className="flex">
                    <div className="flex-1 uploader">
                        <FileUpload name="demo[]" accept="image/*" customUpload uploadHandler={customBase64Uploader} maxFileSize={1000000} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                    </div>

                </div>

                <div className="footer">
                    <button disabled={saveDisabled} className="save-button" type="button" onClick={() => {
                        setSaveDisabled(true);
                        save(formData);
                    }
                    }>Save</button>
                    <button type="button" className="cancel-button" onClick={modalData.closeModal}>Cancel</button>
                </div>
            </div >
        </div >
    );
}