module.exports = {
    userMaps: function () {
        return {
            username: "Username",
            email: "E-Mail",
            displayName: "Display Name",
            firstName: "First name",
            lastName: "Last Name"
        }
    },

    teamMaps: function () {
        return {
            ref: "Abbreviation",
            name: "Name",
            description: "Description"
        }
    },

    roleMaps: function () {
        return {
            ref: "Abbreviation",
            name: "Name",
            description: "Description",
            level: "Level"
        }
    },

    deviceUserMaps: function () {
        return {
            username: "Username",
            email: "E-Mail",
            displayName: "Display Name",
            firstName: "First name",
            lastName: "Last Name",
            teamsString: "Teams"
        }
    },

    eventMaps: function () {
        return {
            name: "Name",
            description: "Description",
            formatDate: "Date",
            active: "Active"
        }
    },

    eventCategoryMaps: function () {
        return {
            ref: "Abbreviation",
            name: "Name"
        }
    },

    permissionMaps: function () {
        return {
            name: "Name"
        }
    },

    routeResourcesMap: function () {
        return {
            name: "Name",
            route: "Route",
            method: "Method"
        }
    },

    ticketTypesMap: function () {
        return {
            name: "Name",
            description: "Description",
            active: "Active"
        }
    },

    ticketsMap: function () {
        return {
            ticketTypeName: "Ticket Type",
            displayName: "Name",
            email: "E-Mail",
            soldAt: "Sold At",
            soldBy: "Sold By",
            price: "Price",
            gender: "Gender",
            isFromWebsite: {
                header: "From Website?",
                type: "boolean"
            },
            isScanned: {
                header: "Scanned?",
                type: "boolean"
            }
        }
    },

    externalTicketsMap: function () {
        return {
            ticketTypeName: "Ticket Type",
            displayName: "Name",
            email: "E-Mail",
            price: "Price",
            source: "Source",
            isScanned: {
                header: "Scanned?",
                type: "boolean"
            }
        }
    },

    createExternalTicketsMap: function () {
        return {
            ref: "QR",
            ticketTypeName: "Ticket Type",
            displayName: "Name",
            email: "E-Mail",
            price: "Price",
        }
    },

    refundMap: function () {
        return {
            ref: "Transaction Ref",
            paymentType: "Paid with",
            refundedAmount: "Refunded",
            amount: "Amount Paid"
        }
    },

    sellersProgressMap: function () {
        return {
            fullName: "Name",
            eventName: "Event",
            ticketTypeName: "Ticket Type",
            ticketPrice: "Price - €",
            ticketTypeCommission: "Ticket Type Commission - €",
            totalTicketsSoldCash: "Qty Sold Cash",
            totalTicketsSoldCashEuro: "Sold Cash - €",
            totalTicketsSoldCard: "Qty Sold Card",
            totalTicketsSoldCardEuro: "Sold Card - €",
            totalTicketsSoldPaymentLink: "Qty Sold Link",
            totalTicketsSoldPaymentLinkEuro: "Sold Link - €",
            totalTicketsSold: "Qty Total Sold",
            totalTicketsSoldEuro: "Total Sold - €",
            totalTicketsSoldEuroExcVAT: "Total Sold Exc. VAT- €",
            commission: "Event Commission - €",
            additionalCommission: "Add. Commission - €",
            totalCommission: "Total Commission - €"
        }
    },

    cashOutMap: function () {
        return {
            deviceUserFullName: "Seller",
            clientUserDisplayName: "Cashed Out By",
            createdAt: "Cashed Out On",
            cashOutPeriod: "Cash Out Period",
            combinedTotalCommission: "Total Commission",
            cashOutDifference: "Amount cashed out",
            description: "Additional Comments",
            combinedTotalTicketsSoldEuro: "Total sold - €",
            combinedTotalTicketsSoldCashEuro: "Total sold Cash - €",
            combinedTotalTicketsSoldCardEuro: "Total sold Card - €",
            combinedTotalTicketsSoldPaymentLink: "Total sold Payment link - €"
        }
    }
}